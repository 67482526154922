import {
  getIsTestActivated,
  getTestVariant,
} from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { AbTestingData } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { IApplicationState } from 'lib/types';

export const globalSearchEnabledTestData: AbTestingData = {
  id: 'LIVE-19547_EnableGlobalSearch',
  name: 'LIVE-19547 EnableGlobalSearch',
  description: 'Enable global search',
};

export const getIsGlobalSearchEnabled = (state: IApplicationState) =>
  getTestVariant(state, globalSearchEnabledTestData.id) !== 'control';

export const getIsGlobalSearchTestFullyInitialized = (state: IApplicationState) =>
  getIsTestActivated(state, globalSearchEnabledTestData.id);

export const useABTest_EnableGlobalSearch = () => {
  useABTestIntegration({
    testId: globalSearchEnabledTestData.id,
  });
};
