export const POPULAR_VENUE_TYPES = {
  maxCards: 8,
  minCards: 3,
  venueTypesImgs: new Set([
    'barn',
    'castle',
    'cityHotelCityVenue',
    'conferenceCentre',
    'countryHouseManorHouse',
    'cruiseBoatYacht',
    'domaine',
    'gardenOutdoor',
    'golfCourse',
    'hotel',
    'museumAttraction',
    'otherVenueType',
    'placeOfWorship',
    'pubRestaurant',
    'resort',
    'rooftop',
    'sportingVenueStadium',
    'statelyHome',
    'townHallRegistryOffice',
    'uniqueVenueType',
    'warehouseFactory',
    'winery',
  ]),
};
