import {
  AnalyticsPhotographer,
  AnalyticsSupplier,
  AnalyticsVenue,
} from 'lib/search/utils/map-suppliers/types';

export const SupplierAnalyticsShape: AnalyticsSupplier = {
  country: undefined,
  county: undefined,
  description: undefined,
  name: undefined,
  supplierURL: undefined,
  thumbext: undefined,
  thumbnail: undefined,
  type: undefined,
};

export const VenueAnalyticsShape: AnalyticsVenue = {
  ...SupplierAnalyticsShape,
  capacityDining: undefined,
  accommodationRooms: undefined,
  venueType: undefined,
  venueStyle: undefined,
};

export const PhotographerAnalyticsShape: AnalyticsPhotographer = {
  ...SupplierAnalyticsShape,
  photographerStyle: undefined,
};
