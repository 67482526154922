import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { toTitleCase } from 'lib/utils';

const formatArea = (fullSearchString: string) => {
  const area = fullSearchString.split('--')[0];
  const areaContextArray = fullSearchString.split('--');

  const areaContext =
    areaContextArray.length > 1 && areaContextArray[2] === CountryCodes.US
      ? `, ${areaContextArray[1]}`
      : '';
  const areaWithContext = `${area.replace(/,|%2C/g, '').replace(/-/g, ' ')}${areaContext
    .replace(/\\+|%2B/g, ' ')
    .replace(/-/g, ' ')}`;

  return toTitleCase(areaWithContext);
};

export default formatArea;
