import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import type { Slug } from '@bridebook/toolbox/src/types';
import { getI18n } from 'lib/i18n/getI18n';
import { imgixBaseURL } from 'lib/utils';

export type SearchLandingPageParams = {
  type: 'venues' | 'suppliers';
};

export const allSupplierTypesByBookingPriority: Slug[] = [
  'venue',
  'photo',
  'florist',
  'video',
  'catering',
  'dress',
  'music',
  'entertainment',
  'cakes',
  'stationery',
  'beauty',
  'menswear',
  'jewellery',
  'transport',
  'decoration',
  'planners',
  'marquee',
];

export const orderSupplierTypesByBookingPriority = (supplierTypes: Slug[]): Slug[] => {
  const supplierTypesByBookingPriority = allSupplierTypesByBookingPriority.filter((type) =>
    supplierTypes.includes(type),
  );

  return supplierTypesByBookingPriority;
};

export const getPopularVenueTypesTexts = (): Record<string, string> => {
  const i18n = getI18n();

  return {
    barn: i18n.t('filters:barn'),
    castle: i18n.t('filters:castle'),
    cityHotelCityVenue: i18n.t('filters:cityHotelCityVenue'),
    conferenceCentre: i18n.t('filters:conferenceCentre'),
    countryHouseManorHouse: i18n.t('filters:countryHouseManorHouse'),
    cruiseBoatYacht: i18n.t('filters:cruiseBoatYacht'),
    gardenOutdoor: i18n.t('filters:gardenOutdoor'),
    golfCourse: i18n.t('filters:golfCourse'),
    hotel: i18n.t('filters:hotel'),
    museumAttraction: i18n.t('filters:museumAttraction'),
    otherVenueType: i18n.t('filters:otherWeddingSpecialist'),
    placeOfWorship: i18n.t('filters:placeOfWorship'),
    pubRestaurant: i18n.t('filters:pubRestaurant'),
    resort: i18n.t('filters:resort'),
    rooftop: i18n.t('filters:rooftop'),
    sportingVenueStadium: i18n.t('filters:sportingVenueStadium'),
    statelyHome: i18n.t('filters:statelyHome'),
    townHallRegistryOffice: i18n.t('filters:townHallRegistryOffice'),
    uniqueVenueType: i18n.t('filters:uniqueVenueType'),
    warehouseFactory: i18n.t('filters:warehouseFactory'),
    winery: i18n.t('filters:winery'),
  };
};

export const getCountryCardPhotoUrl = (countryCode: CountryCodes): string =>
  `${imgixBaseURL}/assets/countriesCardsPhotos/${countryCode}.jpg`;
