/**
 * Basic Storage Manager
 *
 * A simple interface to use Local Storage. Can be used in any place.
 *
 * @param storageKey
 */
export const BasicStorageManager = <T>({ storageKey }: { storageKey: string }) => ({
  set: (item: T): boolean => {
    if (typeof window === 'undefined' || !window.localStorage) {
      return false;
    }
    window.localStorage.setItem(storageKey, JSON.stringify(item));
    return true;
  },

  get: (): T | null => {
    if (typeof window === 'undefined' || !window.localStorage) {
      return null;
    }
    const item = window.localStorage.getItem(storageKey);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  },

  remove: () => {
    if (typeof window === 'undefined' || !window.localStorage) {
      return null;
    }
    window.localStorage.removeItem(storageKey);
    return true;
  },
});
