import { useMemo } from 'react';
import createPersistedState from 'use-persisted-state';
import { addItem, findInArrayByKey, removeItem, updateItem } from '../utils/helpers';

/**
 * Array Storage Hook Manager
 *
 * Creates a hook to set and read data (an array of objects) from Local Storage.
 * Important! Because it's a hook, it can be used only in components.
 *
 * @param storageKey - unique store identifier
 * @param maxItems - (optional) if specified storage will act like a FIFO queue
 */
export const ArrayStorageHookManager = <T extends object>({
  storageKey,
  maxItems,
}: {
  storageKey: string;
  maxItems?: number;
}) => {
  const usePersistedState = createPersistedState<T[]>(storageKey);
  const initialState = useMemo(() => [], []) as T[];
  const [_items, setItems] = usePersistedState(initialState);

  const items = _items || [];
  const clear = () => {
    setItems([]);
  };
  /**
   * Get default manager
   * @param compareKey - property of given objects, which will be used
   * to determine if the item has already been saved before
   */
  const get = ({ compareKey }: { compareKey: keyof T }) => ({
    list: items,
    add: addItem({
      items,
      setItems,
      maxItems,
      findItemFn: findInArrayByKey(compareKey),
    }),
    update: updateItem({
      items,
      setItems,
      maxItems,
      findItemFn: findInArrayByKey(compareKey),
    }),
    remove: removeItem({
      items,
      setItems,
    }),
    clear,
  });

  /**
   * Get manager with a custom compare function
   * @param customFindItemFn - use custom function to determine if the item
   * has already been saved before
   */
  const getCustom = ({
    customFindItemFn,
  }: {
    customFindItemFn: (items: T[], item: T) => T | undefined;
  }) => ({
    list: items,
    add: addItem({
      items,
      setItems,
      maxItems,
      findItemFn: customFindItemFn,
    }),
    update: updateItem({
      items,
      setItems,
      maxItems,
      findItemFn: customFindItemFn,
    }),
    remove: removeItem({
      items,
      setItems,
    }),
    clear,
  });

  return {
    get,
    getCustom,
  };
};
