const formatQuizFilters = (quizFilters: Record<string, Record<string, boolean>>) =>
  Object.values(quizFilters).reduce(
    (acc, filters) => ({
      ...acc,
      ...filters,
    }),
    {},
  );

export default formatQuizFilters;
