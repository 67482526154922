import { type, uniq } from 'ramda';

interface IProps {
  name: string;
  open: boolean;
  accordionList: Array<string>;
}

// adds/removes an accordion name to a list of opened ones
const manageSearchFilterAccordionList = ({
  name,
  open,
  accordionList = [],
}: IProps): Array<string> => {
  let openedList: Array<string> = [];

  if (type(name) === 'String' && type(open) === 'Boolean' && type(accordionList) === 'Array') {
    openedList = [...accordionList];

    if (open) {
      openedList.push(name);
    } else {
      const index = openedList.indexOf(name);
      openedList.splice(index, 1);
    }
  }

  return uniq(openedList);
};

export default manageSearchFilterAccordionList;
