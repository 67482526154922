import { getTestVariant } from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-21094_ApplyOnboardingFilterPreferencesToSearchResults';

export const countriesWithApplyOnboardingFilterPreferencesToSearchResultsTest = CountryCodes.GB;

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_ApplyOnboardingFilterPreferencesToSearchResults = (
  state: IApplicationState,
) => getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_ApplyOnboardingFilterPreferencesToSearchResults = () => {
  // If needed, specify some additional trigger conditions
  // which must be true to start the test
  const market = useMarket();
  const triggerCondition =
    countriesWithApplyOnboardingFilterPreferencesToSearchResultsTest.includes(market.country);

  useABTestIntegration({ testId, triggerCondition });
};
