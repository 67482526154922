import { getSupplierUrl, mergeClean } from '@bridebook/toolbox';
import { IUISupplier } from '@bridebook/toolbox/src/types';

export const mapSupplierToAnalyticsRecord =
  <T>(shape: T) =>
  (supplier: IUISupplier) =>
    mergeClean(shape, {
      ...supplier,
      supplierURL: getSupplierUrl(supplier),
    });
