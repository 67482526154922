import { equals } from 'ramda';

/**
 * Find an item in array by given key.
 * You need to provide a unique property of an object (e.g. ID) to compare items
 * @param key
 */
export const findInArrayByKey =
  <T extends object>(key: keyof T) =>
  (items: T[] = [], item: T): T | undefined =>
    items.find(
      (existingItem) => typeof existingItem[key] !== 'undefined' && existingItem[key] === item[key],
    );

/**
 * Adds an object to the beginning of an array of objects
 * @param items - array of objects
 * @param item - objects to be added
 * @param maxItems - if specified, function will act as a FIFO queue
 */
export const addToArray = <T extends object>(items: T[] = [], item: T, maxItems?: number) => {
  const otherItems = maxItems ? items.slice(0, maxItems - 1) : items;
  return [item, ...otherItems];
};

/**
 * Updates item in Array if it exists as determined by provided function
 * @param items - array of objects
 * @param newItem - new object to replace previous
 * @param findItemFn - Function that returns an item in array
 */
export const updateInArray = <T extends object>(
  items: T[] = [],
  newItem: T,
  findItemFn: (items: T[], item: T) => T | undefined,
) => {
  const existingItem = findItemFn(items, newItem);

  if (existingItem) {
    const itemIndex = items.indexOf(existingItem);
    const newItems = [...items];
    newItems[itemIndex] = newItem;
    return newItems;
  }
  return items;
};

/**
 * Uses a given itemExistsFn function to check if an object already exists in items,
 * if not then it is added to an array and calls setItems with an updated array.
 * @param items
 * @param setItems
 * @param itemExistsFn
 * @param maxItems
 */
export const addItem =
  <T extends object>({
    items = [],
    setItems,
    findItemFn,
    maxItems,
  }: {
    items: T[];
    setItems: (items: T[]) => void;
    findItemFn: (items: T[], item: T) => T | undefined;
    maxItems?: number;
  }) =>
  (newItem: T) => {
    if (!Boolean(findItemFn(items, newItem))) {
      setItems(addToArray(items, newItem, maxItems));
    }
  };

/**
 * Update Item from in storage array based on findItemFn matcher
 * @param items
 * @param setItems
 * @param findItemFn
 * @param maxItems
 * @maxItems
 */
export const updateItem =
  <T extends object>({
    items = [],
    setItems,
    findItemFn,
    maxItems,
  }: {
    items: T[];
    setItems: (items: T[]) => void;
    findItemFn: (items: T[], item: T) => T | undefined;
    maxItems?: number;
  }) =>
  (newItem: T) => {
    if (findItemFn(items, newItem)) {
      setItems(updateInArray(items, newItem, findItemFn));
    } else {
      setItems(addToArray(items, newItem, maxItems));
    }
  };

/**
 * Remove Item from locastorage array
 * @param items
 * @param setItems
 */
export const removeItem =
  <T extends object>({ items = [], setItems }: { items: T[]; setItems: (items: T[]) => void }) =>
  (itemToRemove: T) => {
    const newItems = items.filter((item) => !equals(item, itemToRemove));
    setItems([...newItems]);
  };
