import type { Slug } from '@bridebook/toolbox/src/types';

export const getIdentityPropsKey = (type?: Slug) => {
  switch (type) {
    case 'venue':
      return 'searchedVenues';
    case 'photo':
      return 'searchedPhotographers';
    default:
      return 'searchedSuppliers';
  }
};
