import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

/**
 * Logged out has page size different from logged in.
 * https://bridebook.atlassian.net/browse/LIVE-18608
 */
const PAGE_SIZE_LOGGED_IN = 18;
const PAGE_SIZE_LOGGED_OUT = 20;

const PAGE_SIZE_LOGGED_OUT_MONETIZED = 30;

/**
 Returns the size value to use based on the provided flags.
 @param {Object} options - The options object.
 @param {boolean} options.articleRHC - Flag indicating whether the size value is being used for an article RHC.
 @param {boolean} options.supplierRankId - Flag indicating whether the size value is being used for a supplier rank id.
 @param {boolean} options.isCompetitorsFetch - Flag indicating whether the size value is being used for a competitor fetch.
 @param {boolean} options.isLoggedIn - Flag indicating whether requester is logged in (LIVE-18608 Increase logged-out page size).
 @returns {number} The size value to use.
 */
export const getPageSize = ({
  articleRHC = false,
  supplierRankId = false,
  isCompetitorsFetch = false,
  isLoggedIn = true,
  countryCode,
}: {
  articleRHC?: boolean;
  supplierRankId?: boolean;
  isCompetitorsFetch?: boolean;
  isLoggedIn?: boolean;
  countryCode?: CountryCodes;
} = {}): number => {
  if (articleRHC) return 8;
  if (supplierRankId && isCompetitorsFetch) return 5000;
  if (supplierRankId) return 500;
  if (!isLoggedIn) {
    return countryCode
      ? gazetteer.getMarketByCountry(countryCode).flags.monetized
        ? PAGE_SIZE_LOGGED_OUT_MONETIZED
        : PAGE_SIZE_LOGGED_OUT
      : PAGE_SIZE_LOGGED_OUT;
  }
  return PAGE_SIZE_LOGGED_IN;
};
