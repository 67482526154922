import { keys, merge, pick } from 'ramda';

/**
 * Function `mergeClean` merges two objects with the keys of first object
 * and properties of second ( i.e. first object as schema )
 */
// const mergeClean = (a: Object, b: Object) => merge(a, pick(keys(a), b));
//
// export default mergeClean;

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export default function mergeClean<A, B>(a: A, b: B) {
  return merge<any, any>(a, pick(keys(a), b)) as unknown as Omit<A, keyof B> &
    Omit<B, keyof Omit<B, keyof A>>;
}
