import {
  ArrayStorageHookManager,
  BasicStorageManager,
} from '@bridebook/toolbox/src/storage-managers';
import { IRecentSearch } from 'lib/search/types';

const RECENT_LOCATION_STORAGE_KEY = 'recent-search';

export const useRecentLocationSearchesStorage = () => {
  /* Only update if new item matches top item in the list */
  const customFindItemFn =
    (key: keyof IRecentSearch) => (items: IRecentSearch[], item: IRecentSearch) =>
      items && items[0] && items[0][key] === item[key] ? items[0] : undefined;

  return ArrayStorageHookManager<IRecentSearch>({
    storageKey: RECENT_LOCATION_STORAGE_KEY,
    maxItems: 12,
  }).getCustom({
    customFindItemFn: customFindItemFn('area'),
  });
};

export const getRecentLocationSearches = (): IRecentSearch[] => {
  const recentLocationSearchesAsString = localStorage.getItem(RECENT_LOCATION_STORAGE_KEY);
  if (!recentLocationSearchesAsString) return [];
  return JSON.parse(recentLocationSearchesAsString);
};

export const recentLocationSearchesStorage = BasicStorageManager<IRecentSearch[]>({
  storageKey: RECENT_LOCATION_STORAGE_KEY,
});
