// user targeting LIVE-7846
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { mapDateToUI, mapToExactDate } from '@bridebook/toolbox/src';
import { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import gazetteer, { type Market } from '@bridebook/toolbox/src/gazetteer';
import { IFetchSearchPromiseArgs } from 'lib/search/types';
import { mapDayToWeekday, mapMonthToSeason } from 'lib/utils';

// matching if budgetInitialTarget greater than 1000 and less than 99000
const getTargetingBudget = (target?: number | null) =>
  target && target >= 1000 && target <= 99000 ? target : null;

// use weekDay if present otherwise parse date and get week day
const getTargetingWeekday = (datePicker: IDatePickerUI | null, market: Market) => {
  if (!datePicker) return null;
  const { weekDay } = datePicker;
  if (weekDay) return weekDay;

  const exactDate = mapToExactDate(datePicker, market);
  if (exactDate) {
    const day = new Date(exactDate).getDay();
    return mapDayToWeekday(day);
  }
  return null;
};

// take season if present otherwise map month to a season
const getTargetingSeason = (datePicker: IDatePickerUI | null) => {
  if (!datePicker) return null;
  const { month, season } = datePicker;
  if (season) return season;
  if (month) return mapMonthToSeason(Number(month));
  return null;
};

const getTargetingYear = (datePicker: IDatePickerUI | null) => {
  if (!datePicker) return null;
  const { year } = datePicker;
  return year ? Number(year) : null;
};

interface IGetTargeting {
  q: Omit<IFetchSearchPromiseArgs, 'slug'>;
  profile: IWedding;
}

const getTargeting = ({ q, profile }: IGetTargeting) => {
  const {
    budget,
    date,
    l10n: { country },
  } = profile;
  const market = gazetteer.getMarketByCountry(country);
  const datePicker = mapDateToUI(date);

  return {
    ...q,
    targetingBudget: getTargetingBudget(budget),
    targetingWeekday: getTargetingWeekday(datePicker, market),
    targetingSeason: getTargetingSeason(datePicker),
    targetingYear: getTargetingYear(datePicker),
  };
};

export default getTargeting;
