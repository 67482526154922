import Router from 'next/router';
import {isEmpty, isNil, pathOr} from 'ramda';
import {ofType} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {auditTime, debounceTime, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {getSupplierUrl} from '@bridebook/toolbox';
import {Gazetteer} from '@bridebook/toolbox/src/gazetteer';
import {SearchFacetsResult} from '@bridebook/toolbox/src/seo-facets';
import {IUISupplier} from '@bridebook/toolbox/src/types';
import SearchFields from 'app-shared/lib/search/search-fields';
import {getLocationName} from 'lib/analytics-utils';
import {getIsCollaborator} from 'lib/analytics-utils/get-is-collaborator';
import {getIsMobile} from 'lib/app/selectors';
import {WebAnalyticsContext} from 'lib/bbcommon/utils/bridebook-analytics';
import {env} from 'lib/env';
import {getCountryCode} from 'lib/i18n/selectors';
import {
  IChangedSearchCategoryAnalytics,
  IChangedSearchLocationAnalytics,
  IChangedSearchLocationAnalyticsDebounced,
  IClickedOnFilteredSearchTileAnalytics,
  IFetchSearchSuccessAction,
  IFetchSearchSuccessAnalytics,
  ISearchTriggerClickedSuppliersPageAnalytics,
  ISetSearchLocationAutocompleteText,
  SearchActionTypes,
} from 'lib/search/action-types';
import {
  changedSearchLocationAnalytics,
  changedSearchLocationAnalyticsDebounced,
  fetchedSearchDataAnalytics,
} from 'lib/search/actions';
import {getDiff} from 'lib/search/utils';
import {getIdentityPropsKey} from 'lib/search/utils/get-identity-props-key';
import mapSupplierListToAnalytics from 'lib/search/utils/map-suppliers/map-supplier-list-to-analytics';
import {Action, IApplicationState, IEpicDeps, ISearchState, SearchResponseFields, TSearchResponse,} from 'lib/types';
import {ProtectedUrls, PublicUrls} from 'lib/url-helper';
import {IFetchVenuerexSuppliersSuccessAction, VenuerexActionTypes,} from 'lib/venuerex/action-types';
import {getVenueBooked} from 'lib/weddings/selectors';
import formatQuizFilters from '../../components/quiz/format-quiz-filters';
import {ISearchFiltersPropertiesGeneric, ISearchPropertiesGeneric} from './types';
import {
  priceFilterSeasonsArray,
  PriceFilterWeekDay,
  priceFilterWeekDayMapping,
  priceFilterWeekDaysArray,
} from './utils/wedding-estimate-price-filter-helpers';
import {Season} from "@bridebook/models/source/models/Weddings.types";
import {mapMonthToSeason} from "lib/utils";
import {
  getVariant_ApplyOnboardingFilterPreferencesToSearchResults
} from "lib/ab-tests/tests/global/LIVE-21094_ApplyOnboardingFilterPreferencesToSearchResults";

const DEFAULT_DEBOUNCE_TIME = 5000;

// do not change strings, it will affect analytics
export enum MapToggleStateAnalytics {
  MapView = 'map',
  ListView = 'list',
}

// do not change string, it will affect analytics
export const presetsSection = 'presetsHeader';

const getSearchBarPropertiesGeneric = (
  getState: () => IApplicationState,
  type: string | boolean = '',
  openState: any = null,
  method: string | null = '',
  activeTab: string = '',
) => {
  const {
    app: {
      previousPath,
      query: { searchPopup },
    },
    ui: { barIsVisible },
    search: {
      request: { slug, area },
      searchModalOptions: { activeTab: activeTabFromState, isVenueSearch },
    },
  } = getState();

  let searchBarCategory = type || slug;

  if ((searchPopup === 'true' || openState !== null) && isVenueSearch) {
    searchBarCategory = 'venue';
  }

  return {
    searchBarToggledStatus: openState === null ? barIsVisible : openState,
    searchBarCategory,
    searchBarSearchTerm: area,
    searchBarToggledMethod: method,
    searchBarSearchType: activeTab || activeTabFromState,
    previousPath,
  };
};

interface ISearchFiltersPropertiesProps {
  name: string;
  value: { [s: string]: number | string };
  tag: string | null;
  location: 'searchModal' | 'filtersModal' | 'searchResults';
  getState: () => IApplicationState;
}

/**
 * Outputs analytics searchFiltersPropertiesGeneric.
 */
const searchFiltersPropertiesGeneric = ({ value, location, name }: ISearchFiltersPropertiesProps): ISearchFiltersPropertiesGeneric => {
  const weddingWeekDays = priceFilterWeekDaysArray.filter(day => value[day]);
  const weddingSeasons = priceFilterSeasonsArray.filter(season => value[season]);

  // Handle the priceSection case specifically
  // getVariant_ApplyOnboardingFilterPreferencesToSearchResults variant 3
  if (name === 'priceSection' && !isEmpty(weddingWeekDays) && !isEmpty(weddingSeasons)) {

    return {
      searchFiltersField: [
        ...weddingWeekDays.length > 0 ? ['weddingWeekDays'] : [],
        ...weddingSeasons.length > 0 ? ['weddingSeasons'] : []
      ],
      searchFiltersLocation: location || 'filtersModal',
      searchFiltersValue: [
        ...weddingWeekDays,
        ...weddingSeasons
      ],
      searchFiltersSection: 'priceSection',
    };
  }

  // Fallback logic for other cases
  if (name === 'misc') {
    return {
      searchFiltersField: 'range',
      searchFiltersLocation: location || 'filtersModal',
      searchFiltersValue: Object.values(value)[0]?.toString(),
      searchFiltersSection: Object.keys(value)[0]?.toString(),
    };
  }

  return {
    searchFiltersField: Object.entries(value)
      .filter(([, value]) => !!value)
      .map(([key]) => key),
    searchFiltersLocation: location || 'filtersModal',
    searchFiltersValue: true,
    searchFiltersSection: name,
  };
};

export interface ISearchSuccessAnalyticsCustomData {
  list?: IUISupplier[];
  fields?: Partial<SearchFields>;
  totalResults?: number;
  filters?: Record<string, Record<string, string | boolean>>;
  user?: boolean;
  distanceSortDisabled?: boolean;
  facetsContent?: SearchFacetsResult | null;
  isNoResultsSearch?: boolean;
  customSearchTerm?: string | null;
  linkedSupplierId?: string;
  linkedSupplierName?: string;
  searchSupplierCategory?: string;
  inlineAdPresent?: boolean;
}

interface ISearchPropertiesGenericProps {
  getState: () => IApplicationState;
  searchTerm?: string;
  query?: Record<string, unknown> | string;
  pageNumber?: number;
  searchResultsLoaded?: number;
  sortBy?: string;
  customData?: ISearchSuccessAnalyticsCustomData;
  searchResponse?: TSearchResponse;
  name?: string;
  value?: { [s: string]: number | string };
}

// sequence of getting data -> first check searchResponse, then customData, then state, then hardcoded values
const searchPropertiesGeneric = ({
  getState,
  pageNumber,
  searchResultsLoaded,
  sortBy,
  customData,
  searchResponse,
  name,
  value,
}: ISearchPropertiesGenericProps): ISearchPropertiesGeneric => {
  const state = getState();

  const {
    app: { previousPath, query: previousQuery },
    search: {
      filters: { data: filters },
      list,
      filtersShown,
      searchModalOptions: { isVenueSearch },
      results,
      totalResults,
      preset,
    },
  } = state;

  const searchResponseFilters =
    isNil(searchResponse?.filters) || isEmpty(searchResponse?.filters)
      ? undefined
      : searchResponse?.filters;
  const customDataFilters =
    isNil(customData?.filters) || isEmpty(customData?.filters) ? undefined : customData?.filters;

  let searchActiveFilters = {
    ...(searchResponseFilters || customDataFilters || filters),
  };

  // Get weddingWeekDays and weddingSeasons from priceSection.
  // we want to get weddingWeekDays and weddingSeasons from filters in redux,
  // because these filters in searchResponseFilters are with the BE format
  // and for analytics we need the FE format.
  // e.g. instead of {priceSection: { spring: true }} in
  // searchResponseFilters will be {weddingMonths: [2, 3, 4]}
  // once we refactor FE to allow arrays and objects in search url
  // this issue could be solved

  // Looks like sometimes when we send this filters.priceSection is empty
  const selectedPriceSection = filters.priceSection ?? (name === 'priceSection' ? value : {});

  const weddingWeekDays = priceFilterWeekDaysArray.filter(day => selectedPriceSection[day]);
  const weddingSeasons = priceFilterSeasonsArray.filter(season =>selectedPriceSection[season]);

  // format priceSection with weddingWeekDays and weddingSeasons if they exist
  // getVariant_WeddingEstimatePriceFilter variant 1
  if (!isEmpty(weddingWeekDays) || !isEmpty(weddingSeasons)) {
    searchActiveFilters = {
      ...searchActiveFilters,
      priceSection: {
        weddingWeekDays,
        weddingSeasons,
      },
    };
  }
  // otherwise use old format
  // getVariant_WeddingEstimatePriceFilter control
  else {
    if (
      searchActiveFilters.priceSection &&
      !searchActiveFilters.priceSection.weddingBudget &&
      !searchActiveFilters.priceSection.venueHirePrice
    ) {
      const priceBracket = Object.keys(searchActiveFilters.priceSection);
      searchActiveFilters = {
        ...searchActiveFilters,
        priceSection: { priceBracket },
      };
    }

    if (
      searchActiveFilters.priceSection?.weddingBudget ||
      searchActiveFilters.priceSection?.venueHirePrice
    ) {
      const priceSection = searchActiveFilters.priceSection;
      searchActiveFilters = {
        ...searchActiveFilters,
        priceSection,
      };
    }
  }

  // TODO remove me, this should use event payload not some random hardcoded values
  let resultsFields: Omit<SearchResponseFields, 'countryCode'> = {
    type: 'venue',
    sort: 'favourites',
    area: '',
    swlat: 0,
    swlon: 0,
    nelat: 0,
    nelon: 0,
    page: 1,
    addressComponents: null,
    searchParams: [],
  };

  if (results.status === 'loaded') {
    resultsFields = { ...results.fields };
  }

  if (searchResponse) {
    resultsFields = { ...searchResponse.fields };
  }

  const swlat = customData?.fields?.swlat || resultsFields.swlat;
  const swlon = customData?.fields?.swlon || resultsFields.swlon;
  const nelat = customData?.fields?.nelat || resultsFields.nelat;
  const nelon = customData?.fields?.nelon || resultsFields.nelon;

  const searchCoords = { swlat, swlon, nelat, nelon };

  const fieldsCategory = pathOr('venue', ['type'])(customData?.fields || resultsFields);

  let searchSupplierCategory = fieldsCategory;

  if (previousQuery.searchPopup === 'true' && isVenueSearch) {
    searchSupplierCategory = 'venue';
  }

  let searchPageNumber = pageNumber;
  if (!pageNumber) {
    searchPageNumber = pathOr(1, ['page'])(customData?.fields || resultsFields);
  }

  const searchTerm = pathOr('', ['area'])(customData?.fields || resultsFields);

  const allActiveFilters = {
    ...searchActiveFilters,
    ...(preset ? { [preset]: { [preset]: true } } : {}),
  };

  return {
    searchSupplierCategory: customData?.searchSupplierCategory || searchSupplierCategory,
    searchTerm: customData?.customSearchTerm || searchTerm,
    searchCoords,
    searchActiveFilters: allActiveFilters,
    searchToggledFiltersStatus: filtersShown || false,
    searchResultsLoaded: searchResultsLoaded || list.length,
    previousPath,
    searchPageNumber: Number(searchPageNumber) || 1,
    searchResultsTotal: customData?.totalResults ?? (totalResults || 0),
    searchSortedBy: sortBy || 'favourites',
    inlineAdPresent: !!customData?.inlineAdPresent,
    ...(customData?.linkedSupplierName && { linkedSupplierName: customData?.linkedSupplierName }),
    ...(customData?.linkedSupplierId && { linkedSupplierId: customData?.linkedSupplierId }),
  };
};

const filterLabelProps = (props: { [k: string]: any }) => {
  const newProps = { ...props };
  delete newProps.loadedSearchResultsList;
  return newProps;
};

/*
 * This epic automatically sends an analytics event when the FETCH_SEARCH_SUCCESS action occurs and a 'track' is set in the action's payload.
 * */
export const sendAnalyticsOnFetchSearchSuccessEpic = (
  action$: Observable<Action>,
  deps: IEpicDeps,
) =>
  action$.pipe(
    ofType(SearchActionTypes.FETCH_SEARCH_SUCCESS),
    withLatestFrom(deps.state$),
    auditTime(1500),
    mergeMap(([action, state]) => {
      const payload = action.payload as IFetchSearchSuccessAction['payload'];
      const { track } = payload;
      return state.users.user && track
        ? of(
            fetchedSearchDataAnalytics({
              filters: payload.filters,
              fields: payload.fields,
              list: payload.results,
              totalResults: payload.totalResults,
            }),
          )
        : of();
    }),
  );

export interface AnalyticsHelpersWindow extends Window {
  _cio?: {
    identify: (args: unknown) => void;
  };
}

declare let window: AnalyticsHelpersWindow;

export const fetchSearchSuggestionsAnalyticsEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS_DEBOUNCED),
    withLatestFrom(state$),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    map(([{ payload }, state]: [Action, IApplicationState]) => {
      const pathname = state.app.pathname;
      const previousPath = state.app.previousPath;
      return {
        type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS,
        payload: {
          searchTerm: payload,
          previousPath,
          pathname,
        },
      };
    }),
  );

export default function searchAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'Supplier search',
    filterLabelProps,
  );
  const { payload } = action;
  let eventHandled = true;
  switch (action.type) {
    case SearchActionTypes.TOGGLE_SEARCH_BAR_ANALYTICS: {
      const { openState, searchBarToggledMethod } = payload;
      track({
        event: 'Toggled search bar',
        ...getSearchBarPropertiesGeneric(getState, false, openState, searchBarToggledMethod),
      });
      break;
    }
    case SearchActionTypes.TOGGLED_SEARCH_TYPE_ANALYTICS: {
      const { activeTab } = payload;
      track({
        event: 'Toggled search type',
        ...getSearchBarPropertiesGeneric(getState, '', null, null, activeTab),
      });
      break;
    }
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS: {
      const isVenueConfirmationPopup = getState().venueConfirmation.showVenueConfirm;
      track({
        previousPath: payload.previousPath,
        event: 'Performed supplier search by name',
        searchedByNameTerm: payload.searchTerm,
        searchedByNameLocation: isVenueConfirmationPopup
          ? 'bookingConfirmationPopup'
          : getLocationName(getState(), payload.pathname),
      });
      break;
    }
    case SearchActionTypes.SEARCH_LOAD_MORE_ANALYTICS: {
      const { pageNumber } = payload;
      track({
        event: 'Clicked load more search results',
        ...searchPropertiesGeneric({ getState, pageNumber }),
      });
      break;
    }

    // TODO: fixme https://bridebook.atlassian.net/browse/LIVE-20010, this event needs to rely on data passed from action (search response), not state data mixed with custom data!
    // FETCH_SEARCH_SUCCESS_ANALYTICS is called sometimes before FETCH_SEARCH_SUCCESS, so it's not really good to rely on state here
    case SearchActionTypes.FETCH_SEARCH_SUCCESS_ANALYTICS: {
      const state = getState();
      const {
        app: { previousQuery },
        search: {
          area: searchTerm,
          prevList,
          list,
          searchSource,
          results,
          isMapView,
          onboardingFilters,
        },
        users: { user },
        weddings: { profile: weddingProfile}
      } = state;
      const payload = action.payload as IFetchSearchSuccessAnalytics['payload'];
      const customData = payload.customData;
      const searchResponse = payload.searchResponse;

      // LIVE-21094: Check if the filters in search include the onboardingFilters
      const onboardingFiltersIncluded = Object.keys(onboardingFilters).every((key) => {
        if (searchResponse?.fields && Object.hasOwn(searchResponse.fields || {}, key)) {
          return (
            (searchResponse?.fields as Record<string, any>)[key] ===
            onboardingFilters[key].toString()
          );
        }
        if (priceFilterSeasonsArray.includes(<Season>key)) {
          const searchResponseSeasons = searchResponse?.fields.weddingMonths?.map(month => mapMonthToSeason(month))
          return searchResponseSeasons?.includes(key) && searchResponseSeasons.every(season => season && onboardingFilters[season])
        }

        if(priceFilterWeekDaysArray.includes(<PriceFilterWeekDay>key)){
          const weekDaysArray = priceFilterWeekDayMapping[<PriceFilterWeekDay>key]
          const searchResponseWeekDays = searchResponse?.fields.weddingWeekDays
          return weekDaysArray.every(weekDay => searchResponseWeekDays?.includes(weekDay)) &&
            searchResponseWeekDays?.every(searchResponseWeekDay => priceFilterWeekDayMapping.monWed.includes(searchResponseWeekDay)? onboardingFilters['monWed'] :onboardingFilters[searchResponseWeekDay])
        }
        return false;
      });

      // firstly try to get fields from action payload, then from state and return undefined if not set
      const determinedEventData = searchResponse
        ? {
            type: searchResponse.fields.type,
            countryName: Gazetteer.getCountryName(searchResponse.fields.countryCode),
            countryCode: searchResponse.fields.countryCode,
            page: searchResponse.fields.page,
          }
        : results.status === 'loaded'
        ? {
            type: results.fields.type,
            countryName: Gazetteer.getCountryName(results.fields.countryCode),
            countryCode: results.fields.countryCode,
            page: results.fields.page,
          }
        : undefined;

      const isLoadMore =
        determinedEventData && Number(determinedEventData.page) > Number(previousQuery.page || 0);

      const usedList = searchResponse?.list || customData?.list || list;

      const loadedSearchResultsList = isLoadMore
        ? getDiff(prevList, usedList)
        : usedList.map((item: IUISupplier) => item.id);

      const loadedSearchResultsCoreScore = usedList
        .filter((item: IUISupplier) => loadedSearchResultsList.includes(item.id))
        .map(({ _score = 0 }) => _score);

      //Following properties should only be sent for variant "3" of the LIVE-21094_ApplyOnboardingFilterPreferencesToSearchResults a/b test
      const shouldIncludePricingInfo = getVariant_ApplyOnboardingFilterPreferencesToSearchResults(state) === '3'
      const suppliersInBudget = usedList.filter(supplier => supplier.insideBudget)
      const budgetMatchedSuppliersCount = shouldIncludePricingInfo?suppliersInBudget.length : undefined;
      const budgetMatchedSuppliersList = shouldIncludePricingInfo? suppliersInBudget.map(supplier => supplier.id): undefined
      const priceRangeList = shouldIncludePricingInfo ? usedList.reduce<Record<string, { minWeddingPriceEstimate: number | string, maxWeddingPriceEstimate: number | string }>>((acc, curr)=> {
        if(curr.minWeddingPriceEstimate || curr.maxWeddingPriceEstimate) {
          acc[curr.id] = {
            minWeddingPriceEstimate: curr.minWeddingPriceEstimate ?? 'not specified',
            maxWeddingPriceEstimate: curr.maxWeddingPriceEstimate ?? 'not specified'
          };
        }
        return acc},{}): undefined;
      const priceFilterFirstTimeLoad = shouldIncludePricingInfo ? searchSource === 'finishedOnboarding' && onboardingFiltersIncluded : undefined

      const trackProps = {
        event: 'Loaded search results',
        ...searchPropertiesGeneric({
          getState,
          searchResultsLoaded: loadedSearchResultsList.length,
          customData,
          searchResponse,
        }),
        adminArea0: searchResponse?.fields.addressComponents?.administrative_area_level_1,
        adminArea1: searchResponse?.fields.addressComponents?.administrative_area_level_2,
        searchView: isMapView ? MapToggleStateAnalytics.MapView : MapToggleStateAnalytics.ListView,
        loadedSearchResultsList,
        loadedSearchResultsCoreScore,
        budgetMatchedSuppliersList,
        budgetMatchedSuppliersCount,
        priceRangeList,
        priceFilterFirstTimeLoad,
        budgetInitialTarget: weddingProfile.budget,
        searchSource: searchSource || 'newSearch',
        onboardingFilters: searchSource === 'finishedOnboarding' && onboardingFiltersIncluded,
        ...(determinedEventData
          ? {
              supplierCountryName: determinedEventData.countryName,
              supplierCountryCode: determinedEventData.countryCode,
            }
          : { supplierCountryName: 'N/A', supplierCountryCode: 'N/A' }),
      };
      if (
        user &&
        determinedEventData &&
        (determinedEventData.type === 'venue' || determinedEventData.type === 'photo')
      ) {
        const identifyProps = {
          [getIdentityPropsKey(determinedEventData.type)]: mapSupplierListToAnalytics(
            usedList,
            determinedEventData.type,
          ),
          searchTerm,
        };
        identify(identifyProps, trackProps, { userId: user?.id });

        if (typeof window?._cio !== 'undefined') {
          window._cio.identify({
            id: user.id,
            email: user?.contacts?.email,
          });
        }
      } else {
        track(trackProps);
      }
      break;
    }

    case VenuerexActionTypes.FETCH_VENUEREX_SUPPLIERS_SUCCESS: {
      const { supplierType, list } = payload as IFetchVenuerexSuppliersSuccessAction['payload'];

      const identityProps = {
        [getIdentityPropsKey(supplierType)]: mapSupplierListToAnalytics(list, supplierType),
      };

      identify(identityProps, undefined, { userId: getState().users.user?.id });
      break;
    }
    case SearchActionTypes.TOGGLED_SEARCH_FILTERS_ANALYTICS: {
      const { method } = payload;
      track({
        event: 'Toggled search filters',
        ...searchPropertiesGeneric({ getState }),
        searchToggledFiltersMethod: method,
      });
      break;
    }

    case SearchActionTypes.UPDATE_SEARCH_FILTERS_ANALYTICS: {
      track({
        event: 'Used search filters',
        ...searchPropertiesGeneric({ getState, name: payload.name, value: payload.value }),
        ...searchFiltersPropertiesGeneric({ ...payload }),
      });
      break;
    }

    case SearchActionTypes.RESET_SEARCH_FILTERS_ANALYTICS:
      track({
        event: 'Reset search filters',
        ...searchPropertiesGeneric({ getState }),
      });
      break;

    case SearchActionTypes.SEARCH_SORT_CHANGE_ANALYTICS: {
      const { sortBy } = payload;
      track({
        event: 'Used sort by',
        ...searchPropertiesGeneric({ getState, sortBy }),
      });
      break;
    }

    case SearchActionTypes.MAP_MARKER_CLICKED_ANALYTICS: {
      const { type, id, name, publicId, town, county } = payload;
      track({
        event: 'User clicked on map pin',
        viewedSupplierCategory: type,
        viewedSupplierId: id,
        viewedSupplierName: name,
        viewedSupplierProfileURL:
          env.COUPLESIDE_URL +
          getSupplierUrl({
            type,
            publicId,
            id,
            name,
            town,
            county,
          }),
      });

      break;
    }

    case SearchActionTypes.INTERACTED_WITH_INJECTED_SNIPPET: {
      const { snippet } = payload;
      track({
        event: 'Interacted with injected snippets',
        snippetType: 'Enquiries',
        snippet,
      });
      break;
    }

    case SearchActionTypes.VIEWED_INJECTED_SNIPPET: {
      const { snippet, snippetType } = payload;
      track({
        event: 'Viewed injected snippets',
        snippetType,
        snippet,
      });
      break;
    }
    case SearchActionTypes.STARTED_QUIZ: {
      const { quizName, location } = payload;
      const previousPath = getState().app.previousPath;
      const venueBooked = getVenueBooked(getState());
      track({
        event: 'User started quiz',
        quizName,
        location,
        previousPath,
        venueBooked,
      });
      break;
    }
    case SearchActionTypes.NO_RESULTS_QUIZ: {
      const { quizName, searchSupplierCategory, location } = payload;
      const state = getState();
      const previousPath = state.app.previousPath;
      const collaborators = state.weddings.collaborators;
      const weddingId = state.weddings.profile.id;
      const userEmail = state.users.user?.contacts?.email;
      const countryCode = getCountryCode(state);
      const mobileApp = getIsMobile(state);
      track({
        event: 'Loaded no quiz match page',
        profileType: 'user',
        searchSupplierCategory,
        quizName,
        location,
        previousPath,
        countryCode,
        userEmail,
        weddingId,
        collaborators,
        mobileApp,
      });
      break;
    }
    case SearchActionTypes.CHOSE_QUIZ_FILTERS: {
      const { quizName, quizStepNumber, quizStepContent, filters } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User chose quiz filters',
        quizName,
        quizStepNumber,
        quizStepContent,
        quizFilters,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.WENT_TO_NEXT_QUIZ_STEP: {
      const { quizName, quizStepNumber, quizStepContent, filters, navigationMethod } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User went to next quiz page',
        quizName,
        quizStepNumber,
        quizStepContent,
        quizFilters,
        navigationMethod,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.REVEALED_QUIZ_MATCHES: {
      const { quizName, quizResultType, quizResult, filters } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User revealed quiz matches',
        quizName,
        quizResultType,
        quizResult,
        quizFilters,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.EXITED_QUIZ: {
      const { quizName, quizStepNumber, quizStepContent } = payload;
      const previousPath = getState().app.previousPath;

      track({
        event: 'User exited quiz',
        quizName,
        quizStepNumber,
        quizStepContent,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.LOADED_SEARCH_PREFERENCE_PAGE: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Loaded search preference page',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.LOADED_RECOMMENDED_SUPPLIER_PAGE: {
      track({
        event: 'Loaded recommended supplier page',
      });
      break;
    }
    case SearchActionTypes.CLICKED_FIND_MY_MATCH: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Clicked find my match',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CHANGED_CATEGORY_SELECTION: {
      const { selectedSupplierCategory } = payload;
      track({
        event: 'Changed category selection',
        selectedSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_SEARCH_MYSELF: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Clicked search myself',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_SHOW_ME_SUPPLIERS: {
      track({
        event: 'Clicked show me suppliers',
      });
      break;
    }
    case SearchActionTypes.USER_WENT_TO_A_PREVIOUS_QUIZ_PAGE: {
      const { quizName, quizStepNumber, quizStepContent } = payload;
      track({
        event: 'User went to a previous quiz page',
        quizName,
        quizStepNumber,
        quizStepContent,
      });
      break;
    }
    case SearchActionTypes.TAB_NAVIGATION_SUPPLIERS: {
      const { selectedTab } = payload as ISearchTriggerClickedSuppliersPageAnalytics['payload'];
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const previousPath = state.app.previousPath;
      const collaborator = getIsCollaborator(state);

      track({
        event: 'Used tab navigation on search landing page',
        category: 'Navigation',
        profileType: 'user',
        collaborator,
        previousPath,
        userEmail,
        selectedTab,
      });
      break;
    }
    // GROWTH FAKE DOOR ANALYTICS below - to be refactored one experiments proves successful
    case SearchActionTypes.VIEWED_BANNER_ANALYTICS: {
      const { bannerText, supplierCategory } = payload;
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      track({
        event: 'Viewed banner',
        ...genericProperties(state),
        bannerText,
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: supplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_BANNER_ANALYTICS: {
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      const { supplierCategory } = payload;
      track({
        event: 'Clicked on banner',
        ...genericProperties(state),
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: supplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_REGISTER_INTEREST_BANNER_ANALYTICS: {
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      track({
        event: 'Clicked register interest on banner popup',
        ...genericProperties(state),
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: 'beauty',
      });
      break;
    }

    case SearchActionTypes.INTERACTED_WITH_AD_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('advertising');
      const {
        actionType,
        adLocation,
        id: adId,
        position: adPosition,
        size: adSize,
        category: adCategory,
        supplier: adSupplierId,
      } = payload;

      track({
        event: 'Interacted with ad',
        actionType,
        adLocation,
        adId,
        adPosition,
        adSize,
        adCategory,
        adSupplierId,
      });
      break;
    }

    default:
      eventHandled = false;
      break;
  }

  if (!eventHandled) {
    const typedAction = action as
      | IChangedSearchCategoryAnalytics
      | IChangedSearchLocationAnalytics
      | IClickedOnFilteredSearchTileAnalytics;

    const state = getState();
    const searchLocation = state.search.searchLocation;
    if (searchLocation.status === 'initialized') {
      const searchTerm = searchLocation.selected.searchText;
      switch (typedAction.type) {
        case SearchActionTypes.CHANGED_SEARCH_CATEGORY_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Changed search category',
            category: 'Supplier search',
            searchTerm,
            ...typedAction.payload,
            searchCategoryChangeSource: getSearchChangeSource(),
          });
          break;
        case SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Changed search location',
            category: 'Supplier search',
            ...typedAction.payload,
            noOfLettersEntered:
              typedAction.payload.notAutocompletedSearchTerm &&
              typedAction.payload.notAutocompletedSearchTerm !== typedAction.payload.newSearchTerm
                ? typedAction.payload.notAutocompletedSearchTerm.length
                : undefined,
            searchLocationChangeSource:
              typedAction.payload.searchLocationChangeSource || getSearchChangeSource(),
          });
          break;
        case SearchActionTypes.CLICKED_ON_FILTERED_SEARCH_TILE_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Clicked on filtered search tile',
            category: 'Supplier search',
            searchTerm,
            searchSupplierCategory:
              typedAction.payload.searchTileFilterCategory === 'supplierType'
                ? typedAction.payload.searchTileFilterValue
                : 'venue',
            ...typedAction.payload,
          });
          break;
      }
    }
  }
}

export const changedSearchLocationAnalyticsDebouncedEpic = (
  action$: Observable<
    IChangedSearchLocationAnalyticsDebounced | ISetSearchLocationAutocompleteText
  >,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(
      SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED,
      SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT,
    ),
    withLatestFrom(state$),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    mergeMap(([action, state]) => {
      if (action.type === SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT) {
        const {
          payload: { searchText },
        } = action;
        const search: ISearchState = state.search;
        const searchLocation = search.searchLocation;
        if (searchLocation.status !== 'initialized' || !search.request.slug) return of();
        const category = search.request.slug;
        return of(
          changedSearchLocationAnalyticsDebounced({
            newSearchTerm: searchText,
            previousSearchTerm: searchLocation.selected.searchText,
            searchSupplierCategory: category,
          }),
        );
      } else if (action.type === SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED) {
        const payload = action.payload;
        return of(changedSearchLocationAnalytics(payload));
      } else {
        return of();
      }
    }),
  );
const getSearchChangeSource = () => {
  const pathname = Router.pathname;
  if (pathname === PublicUrls.searchLanding) {
    return 'searchLandingPage';
  } else if (pathname === ProtectedUrls.home) {
    return 'home';
  } else {
    return 'searchResults';
  }
};

const genericProperties = (state: IApplicationState) => {
  const previousPath = state.app.previousPath;
  return {
    previousPath,
  };
};
