import Router from 'next/router';
import { getSearchUrlWithFilters } from '@bridebook/toolbox/src';
import { IRecentSearch } from 'lib/search/types';

export const navigateToRecentSearch = async (
  prediction?: IRecentSearch,
  defaultSearch?: any,
  hasFullDirectory: boolean = true,
) => {
  const search: IRecentSearch = prediction || defaultSearch;
  const slug = hasFullDirectory && search.slug ? search.slug : 'venue';

  const as = getSearchUrlWithFilters({
    slug,
    area: search.area,
    placeId: search.placeId,
    areaContext: search.areaContext,
    filters: search.filters || {},
    as: true,
  });

  await Router.push(as).then(() => window.scrollTo(0, 0));
};
