import { isEmpty, omit } from 'ramda';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { defaultSortValue } from 'lib/search/constants';
import { createQs, makeSearchQuery } from 'lib/utils';

export const getSearchPaginationUrl = ({
  page = 0,
  sortBy = '',
  distanceSortDisabled = false,
  sort,
  filtersState = {},
  searchOnMoveData,
  preset,
  noSort,
  market,
  facetsParams = [],
  locationArea,
  searchParams = [],
  locationSlug,
  placeId,
}: {
  market: Market;
  page?: number;
  sortBy?: string;
  distanceSortDisabled?: boolean;
  sort?: string;
  filtersState?: Record<string, string>;
  searchOnMoveData?: {
    searchOnMove: boolean;
    zoom: number;
    nelat: number;
    nelon: number;
    swlat: number;
    swlon: number;
  };
  preset?: string;
  noSort?: boolean;
  facetsParams?: string[];
  searchParams?: string[];
  locationSlug?: Slug | UrlFriendlySlug;
  locationArea?: string;
  placeId?: string;
}) => {
  const slug = searchParams?.[0]
    ? (searchParams[0].substring('wedding-'.length) as UrlFriendlySlug)
    : locationSlug || 'venues';

  const area = searchParams?.[1] || locationArea || market.country;

  const q = omit(['searchParams'], {
    query: { slug, area, placeId },
    slug,
    area,
    placeId,
  });

  const allSearchConditions: Record<string, any> = {
    ...filtersState,
    ...(searchOnMoveData || {}),
    ...(preset ? { preset } : {}),
  };

  // If facets available use them in format {area}/barn/luxury etc and exclude
  // filters from query string
  const facetsStr = isEmpty(facetsParams) ? '' : `/${facetsParams.sort().join('/')}`;
  const searchQuery = makeSearchQuery(q, omit(['searchParams'], allSearchConditions), page);
  const searchQueryWithoutFacets = omit(facetsParams, searchQuery);
  const isDistance = sortBy === 'distance';
  const sortOption =
    distanceSortDisabled && isDistance ? defaultSortValue : sortBy || sort || defaultSortValue;

  const resultWithSort = createQs({
    ...searchQueryWithoutFacets,
    ...(!noSort && { sort: sortOption }),
  });

  return {
    as: `/search/wedding-${slug}/${area}${facetsStr}${
      resultWithSort === '?' ? '' : resultWithSort
    }`,
  };
};

export default getSearchPaginationUrl;
