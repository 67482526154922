import {
  fetchVenuerexSuppliersSuccess,
  getVenuerexModalState,
  toggleGoToVenuerexModal,
} from './actions';

export enum VenuerexActionTypes {
  TOGGLE_GO_TO_VENUEREX_MODAL = '[Venuerex] Toggle go to venuerex modal',
  GET_VENUEREX_MODAL_STATE = '[Venuerex] Get venuerex modal state',
  SET_VENUEREX_COUNT_TO_MODAL = '[Venuerex] Set count to venuerex modal',
  FETCH_VENUEREX_SUPPLIERS_SUCCESS = '[Venuerex] Fetch venuerex suppliers success',
}

export type IToggleGoToVenuerexModalAction = ReturnType<ReturnType<typeof toggleGoToVenuerexModal>>;

export type IGetVenuerexModalStateAction = ReturnType<typeof getVenuerexModalState>;

export type IFetchVenuerexSuppliersSuccessAction = ReturnType<typeof fetchVenuerexSuppliersSuccess>;
