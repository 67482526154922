import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import mapPhotographersToAnalytics from 'lib/search/utils/map-suppliers/map-photographers-to-analytics';
import mapSuppliersToAnalytics from 'lib/search/utils/map-suppliers/map-suppliers-to-analytics';
import mapVenuesToAnalyticsVenues from 'lib/search/utils/map-suppliers/map-venues-to-analytics';
import {
  AnalyticsPhotographer,
  AnalyticsSupplier,
  AnalyticsVenue,
} from 'lib/search/utils/map-suppliers/types';

export function mapSupplierListToAnalytics<T extends Slug>(
  list: IUISupplier[],
  type?: T,
): T extends 'venue'
  ? AnalyticsVenue[]
  : T extends 'photo'
  ? AnalyticsPhotographer[]
  : AnalyticsSupplier[];

export function mapSupplierListToAnalytics(list: IUISupplier[], type?: Slug) {
  const topSuppliers = list?.slice?.(0, 3) || [];
  switch (type) {
    case 'venue':
      return mapVenuesToAnalyticsVenues(topSuppliers);
    case 'photo':
      return mapPhotographersToAnalytics(topSuppliers);
    default:
      return mapSuppliersToAnalytics(topSuppliers);
  }
}

export default mapSupplierListToAnalytics;
