import esb from 'elastic-builder';
import { PremiumTiers } from '../constants';

export type SortByOption = 'favourites' | 'popular' | 'new' | 'distance' | 'seoScore';
export type BoundingBoxType = { swlat: number; swlon: number; nelat: number; nelon: number };
export type Location = { lat: number; lon: number };
export type Coordinates = { lat: number; lng: number };

export const isTruthy = <T>(value: T): value is Exclude<T, undefined | null | false> =>
  Boolean(value);

/**
 * Same as `PremiumTiers` with `PremiumTiers.Tier_2_5` omitted.
 */
export enum VenueTier {
  Unverified = PremiumTiers.Unverified,
  Tier_0 = PremiumTiers.Tier_0,
  Tier_1 = PremiumTiers.Tier_1,
  Tier_2 = PremiumTiers.Tier_2,
  Tier_3 = PremiumTiers.Tier_3,
  Tier_4 = PremiumTiers.Tier_4,
}

export interface IUserTargeting {
  targetingBudget?: number;
  targetingYear?: number;
  targetingSeason?: string;
  targetingWeekday?: string;
}

export type QueriesArray = esb.Query[];

export type ScoreFunctionArray = esb.ScoreFunction[];

export interface ISupplierSearchRank {
  id: string;
  county?: string;
  profileScore?: number;
  responseScore?: number;
  name?: string;
  position?: number;
  searchPositionPredictions?: {
    tier0?: number;
    tier1?: number;
    tier2?: number;
    tier3?: number;
  };
}

export enum SearchType {
  Town = 'town',
  Region = 'region',
  County = 'county',
  Country = 'country',
  CustomArea = 'customArea',
  Map = 'map',
}
